import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash-es'

export default class extends Controller<HTMLElement> {
  static readonly targets = ['popover', 'form']

  declare popoverTarget: HTMLElement
  declare formTarget: HTMLFormElement

  initialize (): void {
    this.searchWithDebounce = debounce(this.search, 300)
  }

  search (event: InputEvent): void {
    const inputValue = (event?.target as HTMLInputElement)?.value
    if (inputValue !== undefined && inputValue !== '') {
      this.popoverTarget.showPopover()
    } else {
      this.popoverTarget.hidePopover()
    }
    this.formTarget.requestSubmit()
  }
}
